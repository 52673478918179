import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Stack, Typography } from "@mui/material";
import InputAlert from "components/form/InputAlert";
import { FormProvider, RHFTextField } from "components/hook-form";
import useAuth from "hooks/useAuth";
import useNavigateCustom from "hooks/useNavigateCustom";
import useSnackbarCustom from "hooks/useSnackbarCustom";
import { PageID } from "pages";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { getPath } from "routes/path";
import * as Yup from "yup";

type FormProps = {
  email: string;
  password: string;
};

const LoginSchema = Yup.object().shape({
  email: Yup.string().required("必須項目です"),
  password: Yup.string().required("必須項目です"),
});

export default function Login() {
  const { success, error } = useSnackbarCustom();
  const { pathname } = useLocation();

  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);

  const { initialized, authenticated, login } = useAuth();

  const { navigateWhenChanged } = useNavigateCustom();

  const form = useForm<FormProps>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(LoginSchema),
  });

  const handleSubmit = async (data: FormProps) => {
    setMessage("");
    setSending(true);
    const ret = await login(data.email, data.password);

    setSending(false);

    if (ret) {
      success("ログイン成功");
    } else {
      error("ログイン失敗");
      setMessage("入力情報を確認してください");
    }
  };

  useEffect(() => {
    if (authenticated === true && pathname === getPath(PageID.LOGIN)) {
      navigateWhenChanged(getPath(PageID.DASHBOARD_OVERVIEW));
    }
  }, [authenticated]);

  return (
    <FormProvider methods={form} onSubmit={form.handleSubmit(handleSubmit)}>
      <Box sx={{ p: 3, pt: 5, mx: "auto", maxWidth: 500 }} textAlign="center">
        <Stack spacing={3}>
          <Typography variant="h5">ログイン</Typography>
          <InputAlert error="none" message={message} />
          <RHFTextField name="email" label="email" size="small" />
          <RHFTextField
            name="password"
            type="password"
            label="password"
            size="small"
          />

          <LoadingButton loading={sending} type="submit" variant="contained">
            ログイン
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
}
