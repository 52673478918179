import { APICommonResponse, ApiId, HttpMethod, makeParam, request } from "api";
import { getUrl } from "./url";

export type 店舗 = {
  shop_id: string;
  deposit: number;
  name: string;
  qr_service_expire_min: number;
  under_amount_when_create: number;
  under_amount_when_auth: number;
  under_amount_when_use: number;
};

export type QRサービス券認証設定 = {
  shop_no: number;
  parking_management_code: string;
  parking_name: string;
  discount_ticket_code: number | null;
};
export type QRサービス券印字設定 = {
  shop_no: number;
  parking_management_code: string;
  parking_name: string;
};
export type QRサービス券取得設定 = {
  qr_service_parking_group_id: string;
  qr_service_parking_group_name: string;
  parking_management_code: string;
  parking_name: string;
  shop_no: number;
  discount_ticket_code: number;
};
export type デポジット異動履歴 = {
  transfer_datetime: Date;
  transfer_reason: string | null;
  transfer_amount: number;
  before_amount: number;
  after_amount: number;
};

// -------店舗一覧取得---------------
export type 店舗一覧取得Request = {
  shop_id?: string;
  email?: string;
  name?: string;
};
export type 店舗一覧取得Response = {
  data: {
    list: 店舗[];
  };
} & APICommonResponse;
export const 店舗一覧取得 = async (param: 店舗一覧取得Request) => {
  const res = await request<店舗一覧取得Response>({
    url: getUrl(ApiId.店舗一覧取得),
    method: HttpMethod.GET,
    data: new URLSearchParams(param),
  });
  return res;
};

// -------店舗新規登録---------------
export type 店舗新規登録Request = {
  name: string;
};
export type 店舗新規登録Response = {
  data: {
    shop_id: string;
  };
} & APICommonResponse;
export const 店舗新規登録 = async (param: 店舗新規登録Request) => {
  const res = await request<店舗新規登録Response>({
    url: getUrl(ApiId.店舗新規登録),
    method: HttpMethod.POST,
    data: makeParam(param),
  });
  return res;
};

// -------店舗削除---------------
export type 店舗削除Request = {
  shop_id: string;
};
export type 店舗削除Response = {} & APICommonResponse;
export const 店舗削除 = async (param: 店舗削除Request) => {
  const res = await request<店舗削除Response>({
    url: getUrl(ApiId.店舗削除),
    method: HttpMethod.POST,
    data: makeParam(param),
  });
  return res;
};

// -------デポジット情報取得---------------
export type デポジット情報取得Request = {};
export type デポジット情報取得Response = {
  data: {
    deposit: number;
  };
} & APICommonResponse;
export const デポジット情報取得 = async (param: デポジット情報取得Request) => {
  const res = await request<デポジット情報取得Response>({
    url: getUrl(ApiId.デポジット情報取得),
    method: HttpMethod.GET,
  });
  return res;
};
// -------デポジット異動履歴一覧取得---------------
export type デポジット異動履歴一覧取得Request = {};
export type デポジット異動履歴一覧取得Response = {
  data: {
    list: デポジット異動履歴[];
  };
} & APICommonResponse;
export const デポジット異動履歴一覧取得 = async (
  param: デポジット異動履歴一覧取得Request
) => {
  const res = await request<デポジット異動履歴一覧取得Response>({
    url: getUrl(ApiId.デポジット異動履歴一覧取得),
    method: HttpMethod.GET,
    data: new URLSearchParams(param),
  });
  return res;
};

// -------デポジットチャージ---------------
export type デポジットチャージRequest = {
  shop_id: string;
  amount: string;
};
export type デポジットチャージResponse = {
  data: {
    shop_id: string;
    deposit: number;
  };
} & APICommonResponse;
export const デポジットチャージ = async (param: デポジットチャージRequest) => {
  const res = await request<デポジットチャージResponse>({
    url: getUrl(ApiId.デポジットチャージ),
    method: HttpMethod.POST,
    data: makeParam(param),
  });
  return res;
};

// -------店舗設定---------------
export type 店舗設定Request = {
  shop_id: string;
  qr_service_expire_min: string;
  under_amount_when_create: string;
  under_amount_when_auth: string;
  under_amount_when_use: string;
};
export type 店舗設定Response = {
  data: {
    shop_id: string;
  };
} & APICommonResponse;
export const 店舗設定 = async (param: 店舗設定Request) => {
  const res = await request<店舗設定Response>({
    url: getUrl(ApiId.店舗設定),
    method: HttpMethod.POST,
    data: makeParam(param),
  });
  return res;
};

// -------店舗QR設定取得---------------
export type 店舗QR設定取得Request = {
  shop_id: string;
};
export type 店舗QR設定取得Response = {
  data: {
    shop_id: string;
    certification: QRサービス券認証設定[];
    printing: QRサービス券印字設定[];
    acquisition: QRサービス券取得設定 | null;
  };
} & APICommonResponse;
export const 店舗QR設定取得 = async (param: 店舗QR設定取得Request) => {
  const res = await request<店舗QR設定取得Response>({
    url: getUrl(ApiId.店舗QR設定取得),
    method: HttpMethod.GET,
    data: new URLSearchParams(param),
  });
  return res;
};

// -------店舗QR設定認証設定新規登録---------------
export type 店舗QR設定認証設定新規登録Request = {
  shop_id: string;
  parking_management_code: string;
  shop_no: string;
};
export type 店舗QR設定認証設定新規登録Response = {} & APICommonResponse;
export const 店舗QR設定認証設定新規登録 = async (
  param: 店舗QR設定認証設定新規登録Request
) => {
  const res = await request<店舗QR設定認証設定新規登録Response>({
    url: getUrl(ApiId.店舗QR設定認証設定新規登録),
    method: HttpMethod.POST,
    data: makeParam(param),
  });
  return res;
};

// -------店舗QR設定認証設定更新---------------
export type 店舗QR設定認証設定更新Request = {
  shop_id: string;
  parking_management_code: string;
  shop_no: string;
};
export type 店舗QR設定認証設定更新Response = {} & APICommonResponse;
export const 店舗QR設定認証設定更新 = async (
  param: 店舗QR設定認証設定更新Request
) => {
  const res = await request<店舗QR設定認証設定更新Response>({
    url: getUrl(ApiId.店舗QR設定認証設定更新),
    method: HttpMethod.POST,
    data: makeParam(param),
  });
  return res;
};

// -------店舗QR設定認証設定追加---------------
export type 店舗QR設定認証設定追加Request = {
  shop_id: string;
  parking_management_code: string;
  discount_ticket_code: string;
};
export type 店舗QR設定認証設定追加Response = {} & APICommonResponse;
export const 店舗QR設定認証設定追加 = async (
  param: 店舗QR設定認証設定追加Request
) => {
  const res = await request<店舗QR設定認証設定追加Response>({
    url: getUrl(ApiId.店舗QR設定認証設定追加),
    method: HttpMethod.POST,
    data: makeParam(param),
  });
  return res;
};

// -------店舗QR設定認証設定削除---------------
export type 店舗QR設定認証設定削除Request = {
  shop_id: string;
  parking_management_code: string;
  discount_ticket_code: string;
};
export type 店舗QR設定認証設定削除Response = {} & APICommonResponse;
export const 店舗QR設定認証設定削除 = async (
  param: 店舗QR設定認証設定削除Request
) => {
  const res = await request<店舗QR設定認証設定削除Response>({
    url: getUrl(ApiId.店舗QR設定認証設定削除),
    method: HttpMethod.POST,
    data: makeParam(param),
  });
  return res;
};

// -------店舗QR設定認証設定削除---------------
export type 店舗QR設定認証設定全削除Request = {
  shop_id: string;
  parking_management_code: string;
};
export type 店舗QR設定認証設定全削除Response = {} & APICommonResponse;
export const 店舗QR設定認証設定全削除 = async (
  param: 店舗QR設定認証設定全削除Request
) => {
  const res = await request<店舗QR設定認証設定全削除Response>({
    url: getUrl(ApiId.店舗QR設定認証設定全削除),
    method: HttpMethod.POST,
    data: makeParam(param),
  });
  return res;
};

// -------店舗QR設定印字設定変更---------------
export type 店舗QR設定印字設定変更Request = {
  shop_id: string;
  parking_management_code: string;
  shop_no: string;
};
export type 店舗QR設定印字設定変更Response = {} & APICommonResponse;
export const 店舗QR設定印字設定変更 = async (
  param: 店舗QR設定印字設定変更Request
) => {
  const res = await request<店舗QR設定印字設定変更Response>({
    url: getUrl(ApiId.店舗QR設定印字設定変更),
    method: HttpMethod.POST,
    data: makeParam(param),
  });
  return res;
};

// -------店舗QR設定印字設定無効化---------------
export type 店舗QR設定印字設定無効化Request = {
  shop_id: string;
  parking_management_code: string;
};
export type 店舗QR設定印字設定無効化Response = {} & APICommonResponse;
export const 店舗QR設定印字設定無効化 = async (
  param: 店舗QR設定印字設定無効化Request
) => {
  const res = await request<店舗QR設定印字設定無効化Response>({
    url: getUrl(ApiId.店舗QR設定印字設定無効化),
    method: HttpMethod.POST,
    data: makeParam(param),
  });
  return res;
};

// -------店舗QR設定取得設定変更---------------
export type 店舗QR設定取得設定変更Request = {
  shop_id: string;
  qr_service_parking_group_id: string;
  shop_no: string;
  discount_ticket_code: string;
};
export type 店舗QR設定取得設定変更Response = {} & APICommonResponse;
export const 店舗QR設定取得設定変更 = async (
  param: 店舗QR設定取得設定変更Request
) => {
  const res = await request<店舗QR設定取得設定変更Response>({
    url: getUrl(ApiId.店舗QR設定取得設定変更),
    method: HttpMethod.POST,
    data: makeParam(param),
  });
  return res;
};

// -------店舗QR設定取得設定無効化---------------
export type 店舗QR設定取得設定無効化Request = {
  shop_id: string;
};
export type 店舗QR設定取得設定無効化Response = {} & APICommonResponse;
export const 店舗QR設定取得設定無効化 = async (
  param: 店舗QR設定取得設定無効化Request
) => {
  const res = await request<店舗QR設定取得設定無効化Response>({
    url: getUrl(ApiId.店舗QR設定取得設定無効化),
    method: HttpMethod.POST,
    data: makeParam(param),
  });
  return res;
};
