import { HasChildren } from "@types";
import { 駐車場マスタ } from "api/parking";
import {
  QRサービス券印字設定,
  QRサービス券取得設定,
  QRサービス券認証設定,
  店舗,
  店舗QR設定取得,
  店舗一覧取得,
} from "api/shop";
import useAPICall from "hooks/useAPICall";
import useDashboard from "hooks/useDashBoard";
import useNavigateCustom from "hooks/useNavigateCustom";
import useSnackbarCustom from "hooks/useSnackbarCustom";
import useShopTabs from "layouts/dashbord/tab/店舗管理Tabs";
import { PageID, TabID } from "pages";
import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPath } from "routes/path";
import 駐車場マスタストア from "storage/cache/駐車場マスタ";

type Context = {
  shop: 店舗 | null;
  certificationSetting: QRサービス券認証設定[] | undefined;
  printingSetting: QRサービス券印字設定[] | undefined;
  acquisitionSetting: QRサービス券取得設定 | null | undefined;
  parkings: 駐車場マスタ[];
  fetch: () => Promise<void>;
  moveToMain: VoidFunction;
};

export const 店舗詳細Context = createContext<Context>({
  shop: null,
  certificationSetting: [],
  printingSetting: [],
  acquisitionSetting: null,
  parkings: [],
  fetch: async () => {},
  moveToMain: () => {},
});

type Props = HasChildren;
function 店舗詳細ContextProvider({ children }: Props) {
  const { shopId: paramShopId } = useParams();

  const [shop, setShop] = useState<店舗 | null>(null);
  const [parkings, setParkings] = useState<駐車場マスタ[]>([]);
  const [certificationSetting, setCertificationSetting] = useState<
    QRサービス券認証設定[] | undefined
  >(undefined);
  const [printingSetting, setPrintingSetting] = useState<
    QRサービス券印字設定[] | undefined
  >(undefined);
  const [acquisitionSetting, setAcquisitionSetting] = useState<
    QRサービス券取得設定 | null | undefined
  >(undefined);
  const { success, error } = useSnackbarCustom();
  const { navigateWhenChanged } = useNavigateCustom();

  const { callAPI: call店舗一覧取得 } = useAPICall({
    apiMethod: 店舗一覧取得,
    backDrop: true,
    onSuccess: ({ data }) => {
      if (data.list.length !== 1) {
        error("データ取得失敗");
        navigateWhenChanged(getPath(PageID.店舗一覧));
        return;
      }
      setShop(data.list[0]);
    },
    onFailed: () => {
      error("データ取得失敗");
      navigateWhenChanged(getPath(PageID.店舗一覧));
    },
  });

  const { callAPI: call店舗QR設定取得 } = useAPICall({
    apiMethod: 店舗QR設定取得,
    backDrop: true,
    onSuccess: ({ data }) => {
      setAcquisitionSetting(data.acquisition);
      setPrintingSetting(data.printing);
      setCertificationSetting(data.certification);
    },
    onFailed: () => {
      error("データ取得失敗");
      navigateWhenChanged(getPath(PageID.店舗一覧));
    },
  });

  const fetch = async () => {
    if (!paramShopId) return;
    call店舗一覧取得({
      shop_id: paramShopId,
    });
    call店舗QR設定取得({
      shop_id: paramShopId,
    });
  };

  const moveToMain = () => {
    navigateWhenChanged(
      getPath([PageID.店舗詳細, TabID.店舗詳細_メイン], {
        query: {
          shopId: shop?.shop_id ?? "BBBBB",
        },
      })
    );
  };

  const { setHeaderTitle } = useDashboard();

  useEffect(() => {
    setHeaderTitle("店舗詳細");
  }, []);

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    駐車場マスタストア.get().then((parkings) => {
      setParkings(parkings);
    });
  }, []);

  return (
    <店舗詳細Context.Provider
      value={{
        shop,
        certificationSetting,
        printingSetting,
        acquisitionSetting,
        parkings,
        fetch,
        moveToMain,
      }}
    >
      <TabInit />
      {shop && children}
    </店舗詳細Context.Provider>
  );
}

function TabInit() {
  const { setTabs, tabId } = useDashboard();
  const { shop } = useContext(店舗詳細Context);
  const { element } = useShopTabs();

  useEffect(() => {
    setTabs(element);
  }, [shop, tabId]);

  return null;
}

export default 店舗詳細ContextProvider;
