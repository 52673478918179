import { Alert, SxProps } from "@mui/material";
import { APIErrorType } from "hooks/useAPICall";
import React, { useEffect, useMemo, useRef } from "react";
import { FieldErrors, FieldValues, FormState } from "react-hook-form";

type Props<T extends FieldValues> = {
  error?: APIErrorType;
  sx?: SxProps;
  getMessage?: (errpr: APIErrorType) => string | null;
  message?: string;
  errorScroll?: boolean;
  formState?: FormState<T>;
};
const InputAlert = ({
  error,
  sx,
  getMessage,
  message: errorMessage,
  errorScroll,
  formState,
}: Props<FieldValues>) => {
  const ref = useRef<HTMLDivElement>(null);

  const message = useMemo(() => {
    if (errorMessage) {
      return errorMessage;
    }
    if (getMessage && error) {
      const m = getMessage(error);
      if (m !== null) {
        return m;
      }
    }
    if (!!formState && Object.keys(formState.errors).length !== 0)
      return "入力項目を確認してください。";
    if (error === APIErrorType.INPUT) return "入力項目を確認してください。";
    if (error === APIErrorType.SERVER)
      return "エラーが発生しております。しばらくお待ちください。";
    if (error === APIErrorType.EXCLUSIVE)
      return "ページの期限が切れています。再度読込を行ってください";
    return "";
  }, [error, errorMessage, getMessage, formState]);

  // エラー時に自動的にスクロール制御
  useEffect(() => {
    if (error !== APIErrorType.NONE && errorScroll) {
      if (ref.current) {
        ref.current.scrollIntoView({ block: "center", behavior: "smooth" });
      }
    }
  }, [error, ref]);

  if (message === "") return null;
  return (
    <Alert severity="error" sx={sx} ref={ref}>
      {message}
    </Alert>
  );
};

export default React.memo(InputAlert);
