import { Dialog, DialogActions, DialogContent, Button } from "@mui/material";
import { HasChildren } from "@types";
import { PageID, TabID } from "pages";
import { createContext, useState } from "react";

type ContextProps = {
  pageId: PageID;
  tabId: TabID;
  setPageId: (pageId: PageID) => void;
  setTabId: (tabId: TabID) => void;
  openDialog: (message: string) => void;
};
const contextInit: ContextProps = {
  pageId: PageID.NONE,
  tabId: TabID.NONE,
  setPageId: (pageId: PageID) => {},
  setTabId: (tabId: TabID) => {},
  openDialog: (message: string) => {},
};
export const PageContext = createContext(contextInit);

type Props = HasChildren;
export function PageContextProvider({ children }: Props) {
  const [pageId, setPageId] = useState<PageID>(PageID.NONE);
  const [tabId, setTabId] = useState<TabID>(TabID.NONE);

  const [open, setOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const openDialog = (message: string) => {
    setOpen(true);
    setDialogMessage(message);
  };

  const close = () => {
    setOpen(false);
  };

  return (
    <PageContext.Provider
      value={{
        pageId,
        tabId,
        setPageId,
        setTabId,
        openDialog,
      }}
    >
      {children}
      <Dialog open={open} onClose={close}>
        <DialogContent>{dialogMessage}</DialogContent>
        <DialogActions>
          <Button onClick={close}>閉じる</Button>
        </DialogActions>
      </Dialog>
    </PageContext.Provider>
  );
}
