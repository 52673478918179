import { APICommonResponse, ApiId, HttpMethod, makeParam, request } from "api";
import { getUrl } from "./url";
import { string } from "yup";
import { サービス券マスタ, 駐車場マスタ } from "./parking";

export type QRサービス券駐車場グループ = {
  id: string;
  name: string;
  parkings: 駐車場マスタ[];
};

// -------QRサービス券取得---------------
export type QRサービス券取得Request = {
  customer_id: string;
  token: string;
  ticket_id?: string;
};
export type QRサービス券取得Response = {
  data: {
    data: string;
    ticket_id: string;
  };
} & APICommonResponse;
export const QRサービス券取得 = async (param: QRサービス券取得Request) => {
  const res = await request<QRサービス券取得Response>({
    url: getUrl(ApiId.QRサービス券取得),
    method: HttpMethod.GET,
    data: new URLSearchParams(param),
  });
  return res;
};

// -------QRサービス券駐車場グループ一覧取得---------------
export type QRサービス券駐車場グループ一覧取得Request = {};
export type QRサービス券駐車場グループ一覧取得Response = {
  data: {
    list: QRサービス券駐車場グループ[];
  };
} & APICommonResponse;
export const QRサービス券駐車場グループ一覧取得 = async (
  param: QRサービス券駐車場グループ一覧取得Request
) => {
  const res = await request<QRサービス券駐車場グループ一覧取得Response>({
    url: getUrl(ApiId.QRサービス券駐車場グループ一覧取得),
    method: HttpMethod.GET,
    data: new URLSearchParams(param),
  });
  return res;
};

// -------QRサービス券駐車場グループ新規登録---------------
export type QRサービス券駐車場グループ新規登録Request = {
  name: string;
};
export type QRサービス券駐車場グループ新規登録Response = {
  data: {
    id: string;
  };
} & APICommonResponse;
export const QRサービス券駐車場グループ新規登録 = async (
  param: QRサービス券駐車場グループ新規登録Request
) => {
  const res = await request<QRサービス券駐車場グループ新規登録Response>({
    url: getUrl(ApiId.QRサービス券駐車場グループ新規登録),
    method: HttpMethod.POST,
    data: makeParam(param),
  });
  return res;
};

// -------QRサービス券駐車場グループ駐車場追加登録---------------
export type QRサービス券駐車場グループ駐車場追加登録Request = {
  id: string;
  parking_management_code: string;
};
export type QRサービス券駐車場グループ駐車場追加登録Response =
  {} & APICommonResponse;
export const QRサービス券駐車場グループ駐車場追加登録 = async (
  param: QRサービス券駐車場グループ駐車場追加登録Request
) => {
  const res = await request<QRサービス券駐車場グループ駐車場追加登録Response>({
    url: getUrl(ApiId.QRサービス券駐車場グループ駐車場追加登録),
    method: HttpMethod.POST,
    data: makeParam(param),
  });
  return res;
};

// -------QRサービス券駐車場グループ駐車場削除登録---------------
export type QRサービス券駐車場グループ駐車場削除登録Request = {
  id: string;
  parking_management_code: string;
};
export type QRサービス券駐車場グループ駐車場削除登録Response =
  {} & APICommonResponse;
export const QRサービス券駐車場グループ駐車場削除登録 = async (
  param: QRサービス券駐車場グループ駐車場削除登録Request
) => {
  const res = await request<QRサービス券駐車場グループ駐車場削除登録Response>({
    url: getUrl(ApiId.QRサービス券駐車場グループ駐車場削除登録),
    method: HttpMethod.POST,
    data: makeParam(param),
  });
  return res;
};

// -------QRサービス券取得用トークン取得---------------
export type QRサービス券取得用トークン取得Request = {};
export type QRサービス券取得用トークン取得Response = {
  data: {
    token: string;
    customer_id: number;
  };
} & APICommonResponse;
export const QRサービス券取得用トークン取得 = async (
  param: QRサービス券取得用トークン取得Request
) => {
  const res = await request<QRサービス券取得用トークン取得Response>({
    url: getUrl(ApiId.QRサービス券取得用トークン取得),
    method: HttpMethod.GET,
    data: makeParam(param),
  });
  return res;
};

// -------QRサービス券取得用トークンリフレッシュ---------------
export type QRサービス券取得用トークンリフレッシュRequest = {};
export type QRサービス券取得用トークンリフレッシュResponse =
  {} & APICommonResponse;
export const QRサービス券取得用トークンリフレッシュ = async (
  param: QRサービス券取得用トークンリフレッシュRequest
) => {
  const res = await request<QRサービス券取得用トークンリフレッシュResponse>({
    url: getUrl(ApiId.QRサービス券取得用トークンリフレッシュ),
    method: HttpMethod.POST,
    data: makeParam(param),
  });
  return res;
};

export type QRサービス券承認チェックReturn = {
  parking: {
    parking_name: string;
    parking_management_code: string;
    publishing_terminal_code: string;
    publishing_date: Date;
    publishing_no: number;
  };
  discount_tickets: サービス券マスタ[];
};
// -------QRサービス券承認チェック---------------
export type QRサービス券承認チェックRequest = {
  data: string;
};
export type QRサービス券承認チェックResponse = {
  data: QRサービス券承認チェックReturn;
} & APICommonResponse;
export const QRサービス券承認チェック = async (
  param: QRサービス券承認チェックRequest
) => {
  const res = await request<QRサービス券承認チェックResponse>({
    url: getUrl(ApiId.QRサービス券承認チェック),
    method: HttpMethod.POST,
    data: makeParam(param),
  });
  return res;
};

// -------QRサービス券承認---------------
export type QRサービス券承認Request = {
  parking_management_code: string;
  publishing_terminal_code: string;
  publishing_date: Date;
  publishing_no: string;
  discount_ticket_code: string;
};
export type QRサービス券承認Response = {} & APICommonResponse;
export const QRサービス券承認 = async (param: QRサービス券承認Request) => {
  const res = await request<QRサービス券承認Response>({
    url: getUrl(ApiId.QRサービス券承認),
    method: HttpMethod.POST,
    data: makeParam(param),
  });
  return res;
};
