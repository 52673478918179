import { useContext, useEffect } from "react";
import { DashboardLayoutContext } from "contexts/DashboardLayoutContext";
import { PageID, TabID } from "pages";

export default function useDashboard(pageId?: PageID, tabId?: TabID) {
  const context = useContext(DashboardLayoutContext);

  useEffect(() => {
    if (pageId) {
      context.setPageId(pageId);
    }
    if (tabId) {
      context.setTabId(tabId);
    } else {
      context.setTabId(TabID.NONE);
    }
  }, [pageId, tabId]);

  return context;
}
