import { Tabs } from "@mui/material";
import { 店舗詳細Context } from "contexts/page/dashboard/shop/店舗詳細Context";
import { PageID, TabID } from "pages";
import { useContext, useMemo } from "react";
import { getPath } from "routes/path";
import { TabProps, useTab } from "./tabutil";

export default function useA店舗管理Tabs() {
  const { shop } = useContext(店舗詳細Context);

  const tabs: TabProps[] = useMemo(() => {
    return [
      {
        label: "詳細",
        tabId: TabID.店舗詳細_メイン,
        path: getPath([PageID.店舗詳細, TabID.店舗詳細_メイン], {
          query: {
            shopId: shop?.shop_id ?? "aaaaa",
          },
        }),
      },
      {
        label: "基本設定",
        tabId: TabID.店舗詳細_基本設定,
        path: getPath([PageID.店舗詳細, TabID.店舗詳細_基本設定], {
          query: {
            shopId: shop?.shop_id ?? "aaaaa",
          },
        }),
      },
      {
        label: "認証設定",
        tabId: TabID.店舗詳細_QR認証設定,
        path: getPath([PageID.店舗詳細, TabID.店舗詳細_QR認証設定], {
          query: {
            shopId: shop?.shop_id ?? "aaaaa",
          },
        }),
      },
      {
        label: "印字設定",
        tabId: TabID.店舗詳細_QR印字設定,
        path: getPath([PageID.店舗詳細, TabID.店舗詳細_QR印字設定], {
          query: {
            shopId: shop?.shop_id ?? "aaaaa",
          },
        }),
      },
      {
        label: "取得設定",
        tabId: TabID.店舗詳細_QR取得設定,
        path: getPath([PageID.店舗詳細, TabID.店舗詳細_QR取得設定], {
          query: {
            shopId: shop?.shop_id ?? "aaaaa",
          },
        }),
      },
    ];
  }, [shop]);

  const { elements, getTabIndex } = useTab(tabs);

  return {
    element: (
      <Tabs
        value={getTabIndex}
        textColor="inherit"
        //   scrollButtons
        //   allowScrollButtonsMobile
        variant="scrollable"
      >
        {elements}
      </Tabs>
    ),
  };
}
