import { HasChildren } from "@types";
import useAuth from "hooks/useAuth";
import Login from "pages/auth/login";
import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

export default function AuthGuard({ children }: HasChildren) {
  const { authenticated, initialized } = useAuth();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  if (!initialized) {
    return null;
  }

  if (!authenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
