import { AppBar, Box, Grid } from "@mui/material";
import { Outlet } from "react-router-dom";

export default function SimpleLayout() {
  return (
    <Box>
      <AppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Grid container>
          <Grid item xs />
          <Grid item xs={5} textAlign="center">
            HT Dashboard
          </Grid>
          <Grid item xs />
        </Grid>
      </AppBar>

      <Outlet />
    </Box>
  );
}
