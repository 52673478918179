import { HOST_API } from "config";
import { ApiId as A } from ".";

const urls = {
  // 共通---------------------------------------
  [A.CSRF_TOKEN]: "sanctum/csrf-cookie",

  // 認証関連 ----------------------------------
  [A.ME]: "me",
  [A.LOGIN]: "login",
  [A.LOGOUT]: "logout",
  [A.顧客成り代わり開始]: "role/switch/customer",
  [A.店舗成り代わり開始]: "role/switch/shop",
  [A.成り代わり終了]: "role/switch/end",

  // ログインユーザ関連 ----------------------------------
  [A.顧客ログインユーザ新規登録]: "login-user/customer/register",
  [A.店舗ログインユーザ新規登録]: "login-user/shop/register",
  [A.顧客ログインユーザ一覧取得]: "login-user/customer/list",
  [A.店舗ログインユーザ一覧取得]: "login-user/shop/list",
  [A.顧客ログインユーザ削除]: "login-user/customer/delete",
  [A.店舗ログインユーザ削除]: "login-user/shop/delete",

  // 顧客関連 ----------------------------------
  [A.顧客マスタ一覧取得]: "customer/list",

  // 駐車場関連 ----------------------------------
  [A.駐車場マスタ一覧取得]: "parking/list",
  [A.サービス券マスタ一覧取得]: "parking/discount-ticket/list",

  // 店舗関連関連 ----------------------------------
  [A.店舗一覧取得]: "shop/list",
  [A.店舗新規登録]: "shop/register",
  [A.店舗削除]: "shop/delete",
  [A.デポジット情報取得]: "shop/deposit",
  [A.デポジット異動履歴一覧取得]: "shop/deposit/transfer/list",
  [A.デポジットチャージ]: "shop/deposit/charge",
  [A.店舗設定]: "shop/config",
  [A.店舗設定]: "shop/config",
  [A.店舗QR設定取得]: "shop/config/detail",
  [A.店舗QR設定認証設定新規登録]: "shop/config/certification/register",
  [A.店舗QR設定認証設定更新]: "shop/config/certification/update",
  [A.店舗QR設定認証設定追加]: "shop/config/certification/add",
  [A.店舗QR設定認証設定削除]: "shop/config/certification/remove",
  [A.店舗QR設定認証設定全削除]: "shop/config/certification/delete",
  [A.店舗QR設定印字設定変更]: "shop/config/printing/enable",
  [A.店舗QR設定印字設定無効化]: "shop/config/printing/disable",
  [A.店舗QR設定取得設定変更]: "shop/config/acquisition/enable",
  [A.店舗QR設定取得設定無効化]: "shop/config/acquisition/disable",

  // QRサービス券関連-------------------------------
  [A.QRサービス券駐車場グループ一覧取得]: "qr-service/parking-group/list",
  [A.QRサービス券駐車場グループ新規登録]: "qr-service/parking-group/register",
  [A.QRサービス券駐車場グループ駐車場追加登録]:
    "qr-service/parking-group/parking/add",
  [A.QRサービス券駐車場グループ駐車場削除登録]:
    "qr-service/parking-group/parking/remove",
  [A.QRサービス券取得用トークン取得]: "qr-service/acquisition/token",
  [A.QRサービス券取得用トークンリフレッシュ]:
    "qr-service/acquisition/token/refresh",
  [A.QRサービス券取得]: "qr-service/get-ticket",
  [A.QRサービス券承認チェック]: "qr-service/certification/check-data-format",
  [A.QRサービス券承認]: "qr-service/certification",
};

const prefixs = {
  [A.CSRF_TOKEN]: "",
};
const DEFAULT_API_URL_PREFIX = "api";

const getPrefix = (apiId: A) => {
  return prefixs[apiId] ?? DEFAULT_API_URL_PREFIX;
};

export const getUrl = (apiId: A) => {
  let url = getPrefix(apiId);
  if (url.length !== 0) {
    url += "/";
  }
  return url + (urls[apiId] ?? "");
};

export const getFullUrl = (apiId: A) => {
  return HOST_API + "/" + getUrl(apiId);
};
