import usePage from "hooks/usePage";
import { TabID } from "pages";
import { useMemo } from "react";
import { Tab } from ".";

export type TabProps = {
  label: string;
  tabId: TabID;
  path?: string;
};

export function useTab(tabs: TabProps[]) {
  const { pageId, tabId } = usePage();

  const elements = useMemo(() => {
    return tabs.map(({ label, path }, index) => {
      return <Tab {...{ label, navigate: path, key: index }} />;
    });
  }, [pageId, tabId, tabs]);

  const getTabIndex = useMemo(() => {
    const index = tabs.findIndex((tab) => {
      return tab.tabId === tabId;
    });

    return index < 0 ? 0 : index;
  }, [tabId, tabs]);

  return {
    elements,
    getTabIndex,
  };
}
