import { Box } from "@mui/material";
import useBackDrop from "hooks/useBackDrop";
import { useEffect } from "react";

export default function LoadingScreen() {
  const { setShowBackDrop, showBackDrop } = useBackDrop();

  useEffect(() => {
    setShowBackDrop(true);
    return () => {
      setShowBackDrop(false);
    };
  }, [showBackDrop]);

  return <Box>Loading...</Box>;
}
