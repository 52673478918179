import { Tabs } from "@mui/material";
import { QRサービス券駐車場グループ管理Context } from "contexts/page/dashboard/parking/QRサービス券駐車場グループ管理Context";
import usePage from "hooks/usePage";
import { PageID, TabID } from "pages";
import { useContext, useMemo } from "react";
import { getPath } from "routes/path";
import { TabProps, useTab } from "./tabutil";

export default function useQRサービス券駐車場グループ管理Tabs() {
  const {} = useContext(QRサービス券駐車場グループ管理Context);
  const { tabId } = usePage();

  const tabs: TabProps[] = useMemo(() => {
    const list: TabProps[] = [
      {
        label: "一覧",
        tabId: TabID.QRサービス券駐車場グループ管理_一覧,
        path: getPath([
          PageID.QRサービス券駐車場グループ管理,
          TabID.QRサービス券駐車場グループ管理_一覧,
        ]),
      },
      {
        label: "新規登録",
        tabId: TabID.QRサービス券駐車場グループ管理_新規登録,
        path: getPath([
          PageID.QRサービス券駐車場グループ管理,
          TabID.QRサービス券駐車場グループ管理_新規登録,
        ]),
      },
    ];

    if (tabId === TabID.QRサービス券駐車場グループ管理_詳細) {
      list.push({
        label: "詳細",
        tabId: TabID.QRサービス券駐車場グループ管理_詳細,
      });
    }

    return list;
  }, [tabId]);

  const { elements, getTabIndex } = useTab(tabs);

  return {
    element: (
      <Tabs
        value={getTabIndex}
        textColor="inherit"
        //   scrollButtons
        //   allowScrollButtonsMobile
        variant="scrollable"
      >
        {elements}
      </Tabs>
    ),
  };
}
