import { Box, Typography, styled } from "@mui/material";
import { Outlet } from "react-router-dom";
import Navigator from "./navigator";
import useResponsive from "hooks/useResponsive";
import { useContext, useEffect, useMemo, useState } from "react";
import Header from "./header";
import useWindowSize from "hooks/useWindowSize";
import {
  DashboardLayoutContext,
  DashboardLayoutContextProvider,
} from "contexts/DashboardLayoutContext";
import useDashboard from "hooks/useDashBoard";
import useAuth from "hooks/useAuth";
import useNavigateCustom from "hooks/useNavigateCustom";
import { getPath } from "routes/path";
import { PageID } from "pages";
import useBackDrop from "hooks/useBackDrop";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright ©Satellite-Technologies Co., Ltd."}
      {new Date().getFullYear()}. All Rights Reserved.
    </Typography>
  );
}

function App() {
  const { initialized, authenticated } = useAuth();

  const { navigateWhenChanged } = useNavigateCustom();

  const { setShowBackDrop } = useBackDrop();

  const [mobileOpen, setMobileOpen] = useState(false);

  const { drawerWidth, innerWidth, contentsWidth } = useDashboard();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    setShowBackDrop(!initialized);
    if (initialized && !authenticated) {
      navigateWhenChanged(getPath(PageID.PAGE_403));
    }
  }, [initialized, authenticated]);

  if (!initialized) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { md: 0 } }}
      >
        <Navigator
          PaperProps={{ style: { width: drawerWidth } }}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
        />
        <Navigator
          PaperProps={{ style: { width: drawerWidth } }}
          sx={{ display: { sm: "block", xs: "none" } }}
        />
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          maxWidth: contentsWidth,
        }}
      >
        <Header onDrawerToggle={handleDrawerToggle} />
        <Box component="main" sx={{ flex: 1, pt: 1, pb: 6, px: 4 }}>
          <Outlet />
        </Box>
        <Box component="footer" sx={{ p: 2 }}>
          <Copyright />
        </Box>
      </Box>
    </Box>
  );
}

export default function DashBoardLayout() {
  return (
    <DashboardLayoutContextProvider>
      <App />
    </DashboardLayoutContextProvider>
  );
}
