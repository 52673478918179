import { PageID as P } from "pages";
import { UserRole } from "./UserRole";

const 共通ルート: P[] = [P.LOGIN, P.LOGOUT, P.成り代わり終了];
const 認証後共通ルート: P[] = [P.DASHBOARD_ENPTY, P.DASHBOARD_OVERVIEW];
const 管理者顧客共通ルート: P[] = [];

const 認可別許可ルート: {
  [route: string]: P[];
} = {
  [UserRole.NONE]: [...共通ルート],
  [UserRole.ADMIN]: [
    ...共通ルート,
    ...認証後共通ルート,
    ...管理者顧客共通ルート,
    P.ログインユーザ_顧客一覧,
    P.ログインユーザ_顧客新規登録,
  ],

  [UserRole.CUSTOMER]: [
    ...共通ルート,
    ...認証後共通ルート,
    ...管理者顧客共通ルート,
    P.ログインユーザ_店舗一覧,
    P.ログインユーザ_店舗新規登録,
    P.店舗一覧,
    P.店舗新規登録,
    P.店舗詳細,
    P.QRサービス券駐車場グループ管理,
  ],

  [UserRole.SHOP]: [
    ...共通ルート,
    ...認証後共通ルート,
    P.サービス券発行用QRコード,
    P.利用履歴,
    P.QRサービス券承認,
  ],
};

export const ページアクセス許可判定 = (role: UserRole, pageId: P): boolean => {
  return !!認可別許可ルート[role].find((val) => {
    return val === pageId;
  });
};
