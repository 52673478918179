import { 駐車場マスタ, 駐車場マスタ一覧取得 } from "api/parking";

class 駐車場マスタストア {
  private list: 駐車場マスタ[] | undefined = undefined;

  async get() {
    if (this.list === undefined) {
      const res = await 駐車場マスタ一覧取得({});
      this.list = res?.data.list ?? [];
    }
    return this.list;
  }

  async clear() {
    this.list = undefined;
  }
}

export default new 駐車場マスタストア();
