import { useFormContext, Controller } from "react-hook-form";
import { Autocomplete, TextField, TextFieldProps } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import TextFieldEx from "../form/TextFieldEx";

// ----------------------------------------------------------------------

export type AutoCompleteOption = {
  label: string;
  value: string;
};

export type AutoCompleteOptionType = AutoCompleteOption | string | null;

export const getValue = (option: AutoCompleteOptionType): string => {
  if (option === null) {
    return "";
  }
  if (typeof option === "object") {
    return option.value;
  }
  if (typeof option === "string") {
    return option;
  }
  return "";
};

type IProps = {
  name: string;
  /**
   * undefined の場合は、オプション確定前と単断する
   */
  options?: AutoCompleteOption[];
  onFix?: VoidFunction;
  readOnly?: boolean;
};

type Props = IProps & TextFieldProps;
export type RHFAutoCompleteProps = Props;

export const getAutoCompleteOption = (
  options: AutoCompleteOption[],
  value: string
): AutoCompleteOptionType => {
  return options.find((option) => option.value === value) ?? null;
};

export default function RHFAutoComplete({
  name,
  options,
  onFix,
  readOnly,
  ...other
}: Props) {
  const { control, watch, setValue } = useFormContext();

  const value: AutoCompleteOption | string | null = watch(name);
  const valueStr = useMemo(() => {
    if (value === null) return "";
    if (value === undefined) return "";
    if (typeof value === "string") {
      return value;
    } else {
      return value.label ?? "";
    }
  }, [value]);

  // string型からAutoCompleteOptionへ変換してフォームへセットする
  useEffect(() => {
    if (typeof value === "string" && options) {
      if (value === "") {
        setValue(name, null);
      } else {
        const val = getAutoCompleteOption(options, value);
        if (val !== null) {
          setValue(name, val);
        }
      }
    }
  }, [value, options]);

  if (readOnly) {
    return (
      <TextFieldEx readOnly {...other} value={valueStr} variant="standard" />
    );
  }
  // if (typeof value === 'string') return null;
  if (typeof value === "string")
    return <TextFieldEx fullWidth {...other} value={""} />;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, ref }, fieldState }) => (
        <Autocomplete
          options={options ?? []}
          fullWidth
          autoComplete
          includeInputInList
          noOptionsText="候補がありません"
          getOptionLabel={(option) => option?.label ?? ""}
          isOptionEqualToValue={(option, value) => {
            // if (typeof value !== 'object') return false;
            return option.value === value.value;
          }}
          onChange={(e, item) => {
            onChange(item);
            if (onFix) {
              onFix();
            }
          }}
          value={value}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...other}
            />
          )}
          ChipProps={{
            style: {
              margin: 0,
            },
          }}
        />
      )}
    />
  );
}
