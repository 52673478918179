import { ExpandLess, ExpandMore } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArticleIcon from "@mui/icons-material/Article";
import SettingsIcon from "@mui/icons-material/Settings";
import { Collapse, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer, { DrawerProps } from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ページアクセス許可判定 } from "auth/route";
import useAuth from "hooks/useAuth";
import useNavigateCustom from "hooks/useNavigateCustom";
import usePage from "hooks/usePage";
import { PageID, TabID } from "pages";
import * as React from "react";
import { PathKey, PathOption, getPageId, getPath } from "routes/path";
import PersonIcon from "@mui/icons-material/Person";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import StoreIcon from "@mui/icons-material/Store";
import LogoutIcon from "@mui/icons-material/Logout";
import UndoIcon from "@mui/icons-material/Undo";

type Group = {
  label: string;
  children: SubGroup[];
};

type SubGroup = {
  label: string | React.ReactNode;
  icon: React.ReactNode;
  children?: Child[];
  whenIsSwitched?: boolean;

  // 子要素を持たない場合は設定
  id?: PathKey;
  option?: PathOption;
};

type Child = {
  label: string | React.ReactNode;
  id: PathKey;
  option?: PathOption;
};

const item = {
  py: "2px",
  px: 3,
  color: "rgba(255, 255, 255, 0.7)",
  "&:hover, &:focus": {
    bgcolor: "rgba(255, 255, 255, 0.08)",
  },
};

const viewItem = {
  py: "2px",
  px: 3,
  color: "rgba(255, 255, 255, 0.7)",
};

const itemCategory = {
  boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
  py: 1.5,
  px: 3,
};

export default function Navigator(props: DrawerProps) {
  const { ...other } = props;

  const { currentName, isSwitched } = useAuth();

  const { navigateWhenChanged } = useNavigateCustom();

  const navigateToDashboardOverview = () => {
    navigateWhenChanged(getPath(PageID.DASHBOARD_OVERVIEW));
  };

  const categories: Group[] = [
    {
      label: "管理メニュー",
      children: [
        {
          label: "代理店管理",
          icon: <StoreIcon />,
          children: [
            {
              label: "代理店一覧",
              id: PageID.店舗一覧,
            },
            {
              label: "代理店新規登録",
              id: PageID.店舗新規登録,
            },
          ],
        },
        {
          label: "駐車場管理",
          icon: <LocalParkingIcon />,
          children: [
            {
              label: (
                <Box>
                  <Typography variant="body2">QRサービス券</Typography>
                  <Typography variant="body2">駐車場グループ管理</Typography>
                </Box>
              ),
              id: [
                PageID.QRサービス券駐車場グループ管理,
                TabID.QRサービス券駐車場グループ管理_一覧,
              ],
            },
          ],
        },
        {
          label: "QRサービス券",
          icon: <ArticleIcon />,
          children: [
            {
              label: "サービス券発行",
              id: PageID.サービス券発行用QRコード,
            },
            {
              label: "利用履歴",
              id: PageID.利用履歴,
            },
          ],
        },
        {
          label: "ログインユーザ管理",
          icon: <PersonIcon />,
          children: [
            {
              label: "顧客一覧",
              id: PageID.ログインユーザ_顧客一覧,
            },
            {
              label: "顧客新規登録",
              id: PageID.ログインユーザ_顧客新規登録,
            },
          ],
        },
        {
          label: "店舗管理",
          icon: <PersonIcon />,
          children: [
            {
              label: "店舗一覧",
              id: PageID.ログインユーザ_店舗一覧,
            },
            {
              label: "店舗新規登録",
              id: PageID.ログインユーザ_店舗新規登録,
            },
          ],
        },
      ],
    },
    {
      label: "アカウント",
      children: [
        {
          label: "スーパーユーザ画面に戻る",
          icon: <UndoIcon />,
          id: PageID.成り代わり終了,
          whenIsSwitched: true,
        },
        { label: "ログアウト", icon: <LogoutIcon />, id: PageID.LOGOUT },
      ],
    },
  ];

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem
          onClick={navigateToDashboardOverview}
          sx={{ ...item, ...itemCategory, fontSize: 22, color: "#fff" }}
        >
          QRサービス券
        </ListItem>
        <ListItem sx={{ ...viewItem, ...itemCategory }}>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText>{}</ListItemText>
          <ListItemText>
            <Typography>{currentName} 様</Typography>
            {!!isSwitched && <Typography color="red">成り代わり中</Typography>}
          </ListItemText>
        </ListItem>

        {categories.map((group, index) => {
          return <Group {...group} key={index} />;
        })}
      </List>
    </Drawer>
  );
}

function Group(group: Group) {
  const { currentRole, isSwitched } = useAuth();
  const { label, children } = group;

  const elements = children
    .filter(
      ({ id }) =>
        !id || ページアクセス許可判定(currentRole, getPageId(id) ?? -1)
    )
    .filter(({ whenIsSwitched }) => whenIsSwitched !== true || isSwitched)
    .map((ele, index) => <SubGroup {...ele} key={index} />);

  if (elements.length === 0) return null;

  return (
    <Box sx={{ bgcolor: "#101F33" }}>
      <ListItem sx={{ py: 2, px: 3 }}>
        <ListItemText sx={{ color: "#fff" }}>{label}</ListItemText>
      </ListItem>
      {elements}
      <Divider sx={{ mt: 2 }} />
    </Box>
  );
}

function SubGroup({ icon, label, id, children, option }: SubGroup) {
  const { pageId } = usePage();
  const { navigateWhenChanged } = useNavigateCustom();

  const { elements, shouldOpen } = useContents(children ?? []);

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(shouldOpen);
  }, [shouldOpen]);

  // 子要素ありの場合
  if (elements && elements.length !== 0) {
    const handleClick = () => {
      setOpen(!open);
    };
    return (
      <>
        <ListItemButton onClick={handleClick} sx={item} selected={false}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{label}</ListItemText>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {elements}
          </List>
        </Collapse>
      </>
    );
  }
  // 子要素なしの場合
  if (id !== undefined) {
    const handleClick = () => {
      if (id) {
        const path = getPath(id, option);
        navigateWhenChanged(path, undefined, { reload: true });
      }
    };
    const selected = getPageId(id) === pageId;
    return (
      <ListItemButton onClick={handleClick} selected={selected} sx={item}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>{label}</ListItemText>
      </ListItemButton>
    );
  }
  return null;
}

function useContents(children: Child[]) {
  const { pageId } = usePage();
  const { navigateWhenChanged } = useNavigateCustom();
  const { initialized, currentRole } = useAuth();

  const [shouldOpen, setShouldOpen] = React.useState(false);

  const elements = React.useMemo(() => {
    setShouldOpen(false);
    return children
      .filter(({ id }) => ページアクセス許可判定(currentRole, getPageId(id)))
      .map(({ label, id, option }, index) => {
        const selected = getPageId(id) === pageId;
        if (selected) {
          setShouldOpen(true);
        }

        const handleClick = () => {
          const path = getPath(id, option);
          navigateWhenChanged(path, undefined, { reload: true });
        };

        return (
          <ListItemButton
            selected={selected}
            sx={{ ...item, pl: 4 }}
            key={index}
            onClick={handleClick}
          >
            <ListItemText primary={label} />
          </ListItemButton>
        );
      });
  }, [pageId, initialized, currentRole]);

  return {
    elements,
    shouldOpen,
  };
}
