import { Dictionary } from "@types";
import { PageID, TabID } from "pages";
import { get, isArray, isString, replace } from "lodash";

export type PathKey = [PageID, TabID?] | PageID;
const makePathKey = (arg: PathKey): string => {
  if (isArray(arg)) {
    const tabStr = arg[1] !== undefined ? "/" + String(arg[1]) : "";
    return String(arg[0]) + tabStr;
  } else {
    return String(arg);
  }
};

export const getPageId = (key: PathKey): PageID => {
  if (isArray(key)) {
    return key[0];
  } else {
    return key;
  }
};

export const getTabId = (key: PathKey): TabID => {
  if (isArray(key)) {
    return key[1] ?? TabID.NONE;
  } else {
    return TabID.NONE;
  }
};

const PATHS_DASHBOARD = {
  [makePathKey(PageID.DASHBOARD_ENPTY)]: "/dashboard/loading",
  [makePathKey(PageID.DASHBOARD_OVERVIEW)]: "/dashboard",
  [makePathKey(PageID.ログインユーザ_顧客一覧)]:
    "/dashboard/login-user/customer/list",
  [makePathKey(PageID.ログインユーザ_顧客新規登録)]:
    "/dashboard/login-user/customer/register",
  [makePathKey(PageID.ログインユーザ_店舗一覧)]:
    "/dashboard/login-user/shop/list",
  [makePathKey(PageID.ログインユーザ_店舗新規登録)]:
    "/dashboard/login-user/shop/register",

  [makePathKey([
    PageID.QRサービス券駐車場グループ管理,
    TabID.QRサービス券駐車場グループ管理_一覧,
  ])]: "/dashboard/qr-service/group/list",
  [makePathKey([
    PageID.QRサービス券駐車場グループ管理,
    TabID.QRサービス券駐車場グループ管理_新規登録,
  ])]: "/dashboard/qr-service/group/register",
  [makePathKey([
    PageID.QRサービス券駐車場グループ管理,
    TabID.QRサービス券駐車場グループ管理_詳細,
  ])]: "/dashboard/qr-service/group/detail/:id",

  [makePathKey(PageID.店舗一覧)]: "/dashboard/shop/list",
  [makePathKey(PageID.店舗新規登録)]: "/dashboard/shop/register",
  [makePathKey([PageID.店舗詳細, TabID.店舗詳細_メイン])]:
    "/dashboard/shop/detail/:shopId",
  [makePathKey([PageID.店舗詳細, TabID.店舗詳細_基本設定])]:
    "/dashboard/shop/detail/setting/:shopId",
  [makePathKey([PageID.店舗詳細, TabID.店舗詳細_QR認証設定])]:
    "/dashboard/shop/detail/setting/qr/certification/:shopId",
  [makePathKey([PageID.店舗詳細, TabID.店舗詳細_QR印字設定])]:
    "/dashboard/shop/detail/setting/qr/printing/:shopId",
  [makePathKey([PageID.店舗詳細, TabID.店舗詳細_QR取得設定])]:
    "/dashboard/shop/detail/setting/qr/acquisition/:shopId",

  [makePathKey(PageID.サービス券発行用QRコード)]:
    "/dashboard/qr-service/acquisition/generate",
  [makePathKey(PageID.利用履歴)]: "/dashboard/qr-service/history",
};

const PATHS = {
  [makePathKey(PageID.NONE)]: "/",
  [makePathKey([PageID.NONE, TabID.NONE])]: "/",

  // 認証
  [makePathKey(PageID.LOGIN)]: "/login",
  [makePathKey(PageID.LOGOUT)]: "/logout",
  [makePathKey(PageID.成り代わり終了)]: "/role/switch/end",

  [makePathKey(PageID.QRサービス券発行申請)]:
    "/qr-service/acquisition/:customerId/:token",
  [makePathKey(PageID.QRサービス券承認)]: "/qr-service/certificate/:data",

  // ダッシュボード----------------
  ...PATHS_DASHBOARD,

  // その他
  [makePathKey(PageID.PAGE_403)]: "/403",
  [makePathKey(PageID.PAGE_404)]: "/404",
};

export type PathOption = {
  page?: number;
  query?: Dictionary;
};
export function getPath(key: PathKey, option?: PathOption) {
  const pageId = getPageId(key);
  const tabId = getTabId(key);

  let path = getRoute(key);

  // ページ番号解決
  path = replacePathParam(path, "page", option?.page ?? 0);

  // その他URLパラメータ変換
  if (option?.query !== undefined) {
    Object.keys(option.query).forEach((key) => {
      const value = get(option.query, key);
      if (value === undefined) return;

      path = replacePathParam(path, key, value);
    });
  }

  return path;
}
export function getFullPath(key: PathKey, option?: PathOption) {
  return window.location.origin + getPath(key, option);
}

export function getListPagePath(key: PathKey, page: number): string {
  return getPath(key, { page });
}

export function getRoute(key: PathKey, exclude?: string): string {
  let path = "";
  if (getTabId(key) === TabID.NONE) {
    path = get(PATHS, makePathKey(getPageId(key)));
  } else {
    path = get(PATHS, makePathKey(key));
  }
  if (!path) {
    // throw new Error("ルート未定義:" + makePathKey(key));
    // console.error("ルート未定義:" + makePathKey(key));
    return "";
  }

  if (exclude) {
    path = replace(path, "/" + exclude + "/", "");
  }

  return path;
}

function replacePathParam(
  sourceStr: string,
  searchStr: string,
  replacement: string | number
): string {
  return replace(
    sourceStr,
    ":" + searchStr,
    isString(replacement) ? replacement : String(replacement)
  );
}
