let id = 0;
export const PageID = {
  NONE: id++,

  // 認証関連 ----------------------------------
  LOGIN: id++,
  LOGOUT: id++,
  成り代わり終了: id++,

  // ダッシュボード系 START ----------------------------------
  DASHBOARD_ENPTY: id++,
  DASHBOARD_OVERVIEW: id++,

  // 顧客管理
  顧客一覧: id++,

  // ログインユーザー管理
  ログインユーザ_顧客一覧: id++,
  ログインユーザ_顧客新規登録: id++,
  ログインユーザ_店舗一覧: id++,
  ログインユーザ_店舗新規登録: id++,

  // QRサービス券駐車場グループ管理
  QRサービス券駐車場グループ管理: id++,

  // 店舗管理
  店舗一覧: id++,
  店舗新規登録: id++,
  店舗詳細: id++,

  サービス券発行用QRコード: id++,
  利用履歴: id++,

  QRサービス券発行申請: id++,
  QRサービス券承認: id++,
  // ダッシュボード系 END ----------------------------------

  PAGE_403: id++,
  PAGE_404: id++,
} as const;

export type PageID = (typeof PageID)[keyof typeof PageID];

id = 0;
export const TabID = {
  NONE: id++,

  店舗詳細_メイン: id++,
  店舗詳細_基本設定: id++,
  店舗詳細_QR認証設定: id++,
  店舗詳細_QR印字設定: id++,
  店舗詳細_QR取得設定: id++,

  QRサービス券駐車場グループ管理_一覧: id++,
  QRサービス券駐車場グループ管理_新規登録: id++,
  QRサービス券駐車場グループ管理_詳細: id++,
} as const;

export type TabID = (typeof TabID)[keyof typeof TabID];
