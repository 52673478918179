import { Tab as MuiTab, TabProps as MuiTabProps } from "@mui/material";
import useNavigateCustom from "hooks/useNavigateCustom";

export type TabProps = {
  navigate?: string;
} & MuiTabProps;
export function Tab({ navigate, ...others }: TabProps) {
  const { navigateWhenChanged } = useNavigateCustom();

  const handleClick = () => {
    if (navigate) {
      navigateWhenChanged(navigate);
    }
  };

  return <MuiTab onClick={handleClick} {...others} />;
}
