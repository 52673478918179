import { APICommonResponse, ApiId, HttpMethod, makeParam, request } from "api";
import { getUrl } from "./url";

export type 駐車場マスタ = {
  parking_management_code: string;
  parking_name: string;
};
export type サービス券マスタ = {
  discount_ticket_code: number;
  ticket_name: string | null;
};

// -------駐車場マスタ一覧取得---------------
export type 駐車場マスタ一覧取得Request = {};
export type 駐車場マスタ一覧取得Response = {
  data: {
    list: 駐車場マスタ[];
  };
} & APICommonResponse;
export const 駐車場マスタ一覧取得 = async (
  param: 駐車場マスタ一覧取得Request
) => {
  const res = await request<駐車場マスタ一覧取得Response>({
    url: getUrl(ApiId.駐車場マスタ一覧取得),
    method: HttpMethod.GET,
    data: new URLSearchParams(param),
  });
  return res;
};

// -------サービス券マスタ一覧取得---------------
export type サービス券マスタ一覧取得Request = {
  parking_management_code: string;
};
export type サービス券マスタ一覧取得Response = {
  data: {
    list: サービス券マスタ[];
  };
} & APICommonResponse;
export const サービス券マスタ一覧取得 = async (
  param: サービス券マスタ一覧取得Request
) => {
  const res = await request<サービス券マスタ一覧取得Response>({
    url: getUrl(ApiId.サービス券マスタ一覧取得),
    method: HttpMethod.GET,
    data: new URLSearchParams(param),
  });
  return res;
};
