import { PageID } from "pages";
import LoadingScreen from "components/LoadingScreen";
import useAuth from "hooks/useAuth";
import SimpleLayout from "layouts/simple";
import { ElementType, Suspense, lazy, useMemo } from "react";
import { RouteObject, useRoutes } from "react-router-dom";
import { getRoute } from "./path";
import DashboardRoutes from "./sub/dashboard";
import QRServiceSimpleLayout from "layouts/qr-service";
import AuthGuard from "guards/AuthGuard";

export const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};
export const Load = (path: string) => {
  return Loadable(lazy(() => import(path)));
};

const CommonRoutes = (): RouteObject => ({
  element: <SimpleLayout />,
  children: [{}],
});

const AuthRoutes = (): RouteObject => ({
  element: <SimpleLayout />,
  children: [
    {
      path: getRoute(PageID.LOGIN),
      element: <Login />,
    },
    {
      path: getRoute(PageID.LOGOUT),
      element: <Logout />,
    },
  ],
});

const QRサービス券Routes = (): RouteObject => ({
  element: <QRServiceSimpleLayout />,
  children: [
    {
      path: getRoute(PageID.QRサービス券発行申請),
      element: <QRサービス券発行申請 />,
    },
  ],
});
const 認証後QRサービス券Routes = (): RouteObject => ({
  element: (
    <AuthGuard>
      <QRServiceSimpleLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: getRoute(PageID.QRサービス券承認),
      element: <QRサービス券承認 />,
    },
  ],
});

export function Routes() {
  const { initialized } = useAuth();

  return useRoutes([
    CommonRoutes(),
    AuthRoutes(),
    QRサービス券Routes(),
    認証後QRサービス券Routes(),
    ...DashboardRoutes(),
    {
      path: "403",
      element: <Page403 />,
    },
    {
      element: <SimpleLayout />,
      children: [
        {
          path: "*",
          element: initialized ? (
            <AuthGuard>
              <Page404 />
            </AuthGuard>
          ) : (
            <LoadingScreen />
          ),
        },
      ],
    },
  ]);
}

// 認証関連 -------------------------------
const Login = Loadable(lazy(() => import("pages/auth/login")));
const Logout = Loadable(lazy(() => import("pages/auth/logout")));

// QRサービス券関連
const QRサービス券発行申請 = Loadable(
  lazy(() => import("pages/qr-service/QRサービス券発行申請"))
);
const QRサービス券承認 = Loadable(
  lazy(() => import("pages/qr-service/QRサービス券承認"))
);

// その他 ---------------------------------

// const Page403 = Loadable(lazy(() => import("pages/common/Page403")));
const Page404 = Loadable(lazy(() => import("pages/common/Page404")));

const Page403 = Load("pages/common/Page403");
