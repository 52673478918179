import { HasChildren } from "@types";
import { APICommonResponse, ResultCode } from "api";
import {
  login as APILogin,
  logout as APILogout,
  me,
  店舗成り代わり開始,
  成り代わり終了,
  顧客成り代わり開始,
} from "api/auth";
import { UserRole } from "auth/UserRole";
import useAPICall from "hooks/useAPICall";
import useNavigateCustom from "hooks/useNavigateCustom";
import { PageID } from "pages";
import { createContext, memo, useEffect, useMemo, useState } from "react";
import { getPath } from "routes/path";
import 駐車場マスタストア from "storage/cache/駐車場マスタ";

type SwitchedUser = {
  user_id: string;
  name: string;
  role: UserRole;
};
type Auth = {
  initialized: boolean;
  authenticated: boolean;

  id: string;
  name: string;
  email: string;

  role: UserRole; // デフォルトロール
  currentRole: UserRole; // 現在のロール
  currentName: string; // 現在のロール

  switchedUser: SwitchedUser | null;
  isSwitched: boolean;

  login: (email: string, password: string) => Promise<boolean>;
  logout: () => Promise<void>;
  me: VoidFunction;
  switchCustomerRole: (userId: string) => Promise<void>;
  switchShopRole: (userId: string) => Promise<void>;
  switchEnd: () => Promise<void>;
};
export const AuthContext = createContext<Auth>({
  initialized: false,
  authenticated: false,

  id: "",
  name: "",
  email: "",

  role: UserRole.NONE,
  currentRole: UserRole.NONE,
  currentName: "",

  switchedUser: null,
  isSwitched: false,

  login: async (email: string, password: string) => false,
  logout: async () => {},
  me: () => {},
  switchCustomerRole: async (userId: string) => {},
  switchShopRole: async (userId: string) => {},
  switchEnd: async () => {},
});

type Props = HasChildren;
function AuthContextProvider({ children }: Props) {
  const [initialized, setInitialized] = useState(false);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState<UserRole>(UserRole.NONE);
  const [switchedUser, setSwitchedUser] = useState<SwitchedUser | null>(null);
  const [shopId, setShopId] = useState<number | null>(null);

  const { navigateWhenChanged } = useNavigateCustom();
  const authenticated = useMemo(() => {
    return !!email;
  }, [email]);

  const currentRole = useMemo(() => {
    if (switchedUser) {
      return switchedUser.role;
    }
    return role;
  }, [switchedUser, role]);

  const currentName = useMemo(() => {
    if (switchedUser) {
      return switchedUser.name;
    }
    return name;
  }, [switchedUser, name]);

  const { callAPI: callMe } = useAPICall({
    apiMethod: me,
    backDrop: true,
    onSuccess: ({ data }) => {
      setInitialized(true);

      setId(data.id);
      setEmail(data.email);
      setName(data.name);
      setRole(data.role);
      if (data.switched_user_id) {
        setSwitchedUser({
          name: data.switched_name ?? "",
          user_id: data.switched_user_id ?? "",
          role: data.switched_role ?? UserRole.NONE,
        });
      }
    },
    onFailed: () => {
      clear();
      setInitialized(true);
    },
  });

  const { callAPI: callLogin } = useAPICall({
    apiMethod: APILogin,
    backDrop: true,
    onSuccess: () => {
      meFetch();
    },
  });

  const { callAPI: callLogout } = useAPICall({
    apiMethod: APILogout,
    backDrop: true,
    onSuccess: () => {
      clear();
    },
  });

  const { callAPI: call顧客成り代わり開始 } = useAPICall({
    apiMethod: 顧客成り代わり開始,
    backDrop: true,
    onSuccess: ({ data }) => {
      const role = data.role as UserRole;
      setSwitchedUser({
        ...data,
        role,
      });
    },
  });
  const { callAPI: call店舗成り代わり開始 } = useAPICall({
    apiMethod: 店舗成り代わり開始,
    backDrop: true,
    onSuccess: ({ data }) => {
      const role = data.role as UserRole;
      setSwitchedUser({
        ...data,
        role,
      });
    },
  });
  const { callAPI: call成り代わり終了 } = useAPICall({
    apiMethod: 成り代わり終了,
    backDrop: true,
    onSuccess: () => {
      setSwitchedUser(null);
    },
  });

  const clear = () => {
    setId("");
    setName("");
    setEmail("");
    setRole(UserRole.NONE);
    setSwitchedUser(null);
    setShopId(null);
  };

  const cacheClear = () => {
    駐車場マスタストア.clear();
  };

  const login = async (email: string, password: string) => {
    const res: APICommonResponse | null = await callLogin({ email, password });
    return res?.result === ResultCode.SUCCESS;
  };
  const logout = async () => {
    await callLogout({});
    console.info("ログアウト");
  };

  const switchCustomerRole = async (user_id: string) => {
    await call顧客成り代わり開始({
      user_id,
    });
    navigateWhenChanged(getPath(PageID.DASHBOARD_OVERVIEW));
    cacheClear();
  };
  const switchShopRole = async (user_id: string) => {
    await call店舗成り代わり開始({ user_id });
    navigateWhenChanged(getPath(PageID.DASHBOARD_OVERVIEW));
    cacheClear();
  };
  const switchEnd = async () => {
    await call成り代わり終了({});
    navigateWhenChanged(getPath(PageID.DASHBOARD_OVERVIEW));
    cacheClear();
  };

  const isSwitched = useMemo(() => {
    return switchedUser !== null;
  }, [switchedUser]);

  const meFetch = () => {
    callMe({});
  };

  useEffect(() => {
    meFetch();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        // Value
        initialized,
        authenticated,
        id,
        name,
        email,

        role,
        currentRole,
        currentName,
        switchedUser,

        // Func
        login,
        logout,
        me: meFetch,
        switchCustomerRole,
        switchShopRole,
        switchEnd,
        isSwitched,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default memo(AuthContextProvider);
