import { OptionsObject, useSnackbar } from "notistack";

export default function useSnackbarCustom() {
  const { enqueueSnackbar } = useSnackbar();

  const info = (message: string, option?: OptionsObject) => {
    enqueueSnackbar(message, { variant: "info", ...option });
  };
  const success = (message: string, option?: OptionsObject) => {
    enqueueSnackbar(message, { variant: "success", ...option });
  };
  const warn = (message: string, option?: OptionsObject) => {
    enqueueSnackbar(message, { variant: "warning", ...option });
  };
  const error = (message: string, option?: OptionsObject) => {
    enqueueSnackbar(message, { variant: "error", ...option });
  };

  return {
    enqueueSnackbar,
    info,
    success,
    warn,
    error,
  };
}
