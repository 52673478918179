import { HasChildren } from "@types";
import { 駐車場マスタ } from "api/parking";
import {
  QRサービス券駐車場グループ,
  QRサービス券駐車場グループ一覧取得,
} from "api/qr-service";
import useAPICall from "hooks/useAPICall";
import useDashboard from "hooks/useDashBoard";
import useNavigateCustom from "hooks/useNavigateCustom";
import useSnackbarCustom from "hooks/useSnackbarCustom";
import useQRサービス券駐車場グループ管理Tabs from "layouts/dashbord/tab/QRサービス券駐車場グループ管理Tabs";
import { PageID, TabID } from "pages";
import { createContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getPath } from "routes/path";
import 駐車場マスタストア from "storage/cache/駐車場マスタ";

type Context = {
  parkings: 駐車場マスタ[];
  groups: QRサービス券駐車場グループ[];
  selectedGroup: QRサービス券駐車場グループ | null;
  fetch: () => Promise<void>;
  moveToList: VoidFunction;
  moveToDetail: (id: string) => void;
};

export const QRサービス券駐車場グループ管理Context = createContext<Context>({
  parkings: [],
  groups: [],
  selectedGroup: null,
  fetch: async () => {},
  moveToList: () => {},
  moveToDetail: (id: string) => {},
});

type Props = HasChildren;
function QRサービス券駐車場グループ管理ContextProvider({ children }: Props) {
  const { id: paramId } = useParams();

  const [parkings, setParkings] = useState<駐車場マスタ[]>([]);
  const [groups, setGroups] = useState<QRサービス券駐車場グループ[]>([]);
  const { success, error } = useSnackbarCustom();
  const { navigateWhenChanged } = useNavigateCustom();

  const selectedGroup = useMemo(() => {
    return groups.find((g) => g.id === paramId) ?? null;
  }, [groups, paramId]);

  const { callAPI: callQRサービス券駐車場グループ一覧取得 } = useAPICall({
    apiMethod: QRサービス券駐車場グループ一覧取得,
    backDrop: true,
    onSuccess: ({ data }) => {
      setGroups(data.list);
    },
    onFailed: () => {
      error("データ取得失敗");
    },
  });

  const fetch = async () => {
    await callQRサービス券駐車場グループ一覧取得({});
  };

  const moveToList = () => {
    navigateWhenChanged(
      getPath(
        [
          PageID.QRサービス券駐車場グループ管理,
          TabID.QRサービス券駐車場グループ管理_一覧,
        ],
        {}
      )
    );
  };
  const moveToDetail = (id: string) => {
    navigateWhenChanged(
      getPath(
        [
          PageID.QRサービス券駐車場グループ管理,
          TabID.QRサービス券駐車場グループ管理_詳細,
        ],
        {
          query: {
            id,
          },
        }
      )
    );
  };

  const { setHeaderTitle } = useDashboard(
    PageID.QRサービス券駐車場グループ管理
  );

  useEffect(() => {
    setHeaderTitle("QRサービス券駐車場グループ管理");
  }, []);

  useEffect(() => {
    fetch();
    駐車場マスタストア.get().then((parkings) => {
      setParkings(parkings);
    });
  }, []);

  return (
    <QRサービス券駐車場グループ管理Context.Provider
      value={{
        parkings,
        groups,
        selectedGroup,
        fetch,
        moveToList,
        moveToDetail,
      }}
    >
      <TabInit />
      {children}
    </QRサービス券駐車場グループ管理Context.Provider>
  );
}

function TabInit() {
  const { setTabs, tabId } = useDashboard();
  const { element } = useQRサービス券駐車場グループ管理Tabs();

  useEffect(() => {
    setTabs(element);
  }, [tabId]);

  return null;
}

export default QRサービス券駐車場グループ管理ContextProvider;
