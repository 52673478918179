import { ページアクセス許可判定 } from "auth/route";
import QRサービス券駐車場グループ管理ContextProvider from "contexts/page/dashboard/parking/QRサービス券駐車場グループ管理Context";
import 店舗詳細ContextProvider from "contexts/page/dashboard/shop/店舗詳細Context";
import AuthGuard from "guards/AuthGuard";
import useAuth from "hooks/useAuth";
import DashboardLayout from "layouts/dashbord";
import { PageID, TabID } from "pages";
import { lazy, useMemo } from "react";
import { Outlet, RouteObject } from "react-router-dom";
import { Loadable } from "routes";
import { getPath } from "routes/path";

export default function DashboardRoutes(): RouteObject[] {
  const { currentRole } = useAuth();

  const children: RouteObject[] = useMemo(() => {
    const Enpty = Loadable(lazy(() => import("pages/dashboard/empty")));
    const Dashboard = Loadable(lazy(() => import("pages/dashboard")));
    const 成り代わり終了 = Loadable(
      lazy(() => import("pages/dashboard/成り代わり終了"))
    );

    const サービス券発行用QRコード = Loadable(
      lazy(() => import("pages/dashboard/qr-service/サービス券発行"))
    );
    const サービス券利用履歴 = Loadable(
      lazy(() => import("pages/dashboard/qr-service/利用履歴一覧"))
    );
    const QRサービス券駐車場管理一覧 = Loadable(
      lazy(
        () => import("pages/dashboard/qr-service/QRサービス券駐車場管理/一覧")
      )
    );
    const QRサービス券駐車場管理新規登録 = Loadable(
      lazy(
        () =>
          import("pages/dashboard/qr-service/QRサービス券駐車場管理/新規登録")
      )
    );
    const QRサービス券駐車場管理詳細 = Loadable(
      lazy(
        () => import("pages/dashboard/qr-service/QRサービス券駐車場管理/詳細")
      )
    );
    const 顧客ログインユーザ一覧 = Loadable(
      lazy(() => import("pages/dashboard/login-user/顧客ログインユーザ一覧"))
    );
    const 顧客ログインユーザ新規登録 = Loadable(
      lazy(
        () => import("pages/dashboard/login-user/顧客ログインユーザ新規登録")
      )
    );
    const 店舗ログインユーザ一覧 = Loadable(
      lazy(() => import("pages/dashboard/login-user/店舗/一覧"))
    );
    const 店舗ログインユーザ新規登録 = Loadable(
      lazy(() => import("pages/dashboard/login-user/店舗/新規登録"))
    );
    const 店舗新規登録 = Loadable(
      lazy(() => import("pages/dashboard/shop/店舗新規登録"))
    );
    const 店舗一覧 = Loadable(
      lazy(() => import("pages/dashboard/shop/店舗一覧"))
    );
    const 店舗詳細 = Loadable(
      lazy(() => import("pages/dashboard/shop/店舗詳細/詳細"))
    );
    const 店舗詳細基本設定 = Loadable(
      lazy(() => import("pages/dashboard/shop/店舗詳細/基本設定"))
    );
    const 店舗詳細QR認証設定 = Loadable(
      lazy(() => import("pages/dashboard/shop/店舗詳細/QR認証設定"))
    );
    const 店舗詳細QR印字設定 = Loadable(
      lazy(() => import("pages/dashboard/shop/店舗詳細/QR印字設定"))
    );
    const 店舗詳細QR取得設定 = Loadable(
      lazy(() => import("pages/dashboard/shop/店舗詳細/QR取得設定"))
    );

    const allChildren: {
      pageId: PageID;
      ele: RouteObject;
    }[] = [
      {
        pageId: PageID.DASHBOARD_ENPTY,
        ele: {
          element: <Enpty />,
          path: getPath(PageID.DASHBOARD_ENPTY),
        },
      },
      {
        pageId: PageID.DASHBOARD_OVERVIEW,
        ele: {
          element: <Dashboard />,
          path: getPath(PageID.DASHBOARD_OVERVIEW),
        },
      },
      {
        pageId: PageID.成り代わり終了,
        ele: {
          element: <成り代わり終了 />,
          path: getPath(PageID.成り代わり終了),
        },
      },
      {
        pageId: PageID.ログインユーザ_顧客一覧,
        ele: {
          element: <顧客ログインユーザ一覧 />,
          path: getPath(PageID.ログインユーザ_顧客一覧),
        },
      },
      {
        pageId: PageID.ログインユーザ_顧客新規登録,
        ele: {
          element: <顧客ログインユーザ新規登録 />,
          path: getPath(PageID.ログインユーザ_顧客新規登録),
        },
      },
      {
        pageId: PageID.ログインユーザ_店舗一覧,
        ele: {
          element: <店舗ログインユーザ一覧 />,
          path: getPath(PageID.ログインユーザ_店舗一覧),
        },
      },
      {
        pageId: PageID.ログインユーザ_店舗新規登録,
        ele: {
          element: <店舗ログインユーザ新規登録 />,
          path: getPath(PageID.ログインユーザ_店舗新規登録),
        },
      },
      {
        pageId: PageID.店舗新規登録,
        ele: {
          element: <店舗新規登録 />,
          path: getPath(PageID.店舗新規登録),
        },
      },
      {
        pageId: PageID.店舗一覧,
        ele: {
          element: <店舗一覧 />,
          path: getPath(PageID.店舗一覧),
        },
      },
      {
        pageId: PageID.店舗詳細,
        ele: {
          element: (
            <店舗詳細ContextProvider>
              <Outlet />
            </店舗詳細ContextProvider>
          ),
          children: [
            {
              element: <店舗詳細 />,
              path: getPath([PageID.店舗詳細, TabID.店舗詳細_メイン]),
            },
            {
              element: <店舗詳細基本設定 />,
              path: getPath([PageID.店舗詳細, TabID.店舗詳細_基本設定]),
            },
            {
              element: <店舗詳細QR認証設定 />,
              path: getPath([PageID.店舗詳細, TabID.店舗詳細_QR認証設定]),
            },
            {
              element: <店舗詳細QR印字設定 />,
              path: getPath([PageID.店舗詳細, TabID.店舗詳細_QR印字設定]),
            },
            {
              element: <店舗詳細QR取得設定 />,
              path: getPath([PageID.店舗詳細, TabID.店舗詳細_QR取得設定]),
            },
          ],
        },
      },
      {
        pageId: PageID.QRサービス券駐車場グループ管理,
        ele: {
          element: (
            <QRサービス券駐車場グループ管理ContextProvider>
              <Outlet />
            </QRサービス券駐車場グループ管理ContextProvider>
          ),
          children: [
            {
              element: <QRサービス券駐車場管理一覧 />,
              path: getPath([
                PageID.QRサービス券駐車場グループ管理,
                TabID.QRサービス券駐車場グループ管理_一覧,
              ]),
            },
            {
              element: <QRサービス券駐車場管理新規登録 />,
              path: getPath([
                PageID.QRサービス券駐車場グループ管理,
                TabID.QRサービス券駐車場グループ管理_新規登録,
              ]),
            },
            {
              element: <QRサービス券駐車場管理詳細 />,
              path: getPath([
                PageID.QRサービス券駐車場グループ管理,
                TabID.QRサービス券駐車場グループ管理_詳細,
              ]),
            },
          ],
        },
      },
      {
        pageId: PageID.サービス券発行用QRコード,
        ele: {
          element: <サービス券発行用QRコード />,
          path: getPath(PageID.サービス券発行用QRコード),
        },
      },
      {
        pageId: PageID.利用履歴,
        ele: {
          element: <サービス券利用履歴 />,
          path: getPath(PageID.利用履歴),
        },
      },
    ];

    return allChildren
      .filter(({ pageId }) => {
        if (currentRole === null) {
          return false;
        }
        return ページアクセス許可判定(currentRole, pageId);
      })
      .map(({ ele }) => ele);
  }, [currentRole]);

  return [
    {
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: children,
    },
  ];
}
