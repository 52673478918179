import { UserRole } from "auth/UserRole";
import { APICommonResponse, ApiId, HttpMethod, makeParam, request } from ".";
import { getUrl } from "./url";

export type Me = {
  id: string;
  name: string;
  email: string;
  role: UserRole;
  shop_id: string | null;
  switched_user_id: string | null;
  switched_name: string | null;
  switched_role: UserRole | null;
};

type MeResponse = {
  data: Me;
} & APICommonResponse;

export const csrfToken = async () => {
  await request({
    url: getUrl(ApiId.CSRF_TOKEN),
    method: HttpMethod.GET,
  });
};

export const me = async () => {
  const res = await request<MeResponse>({
    url: getUrl(ApiId.ME),
    method: HttpMethod.GET,
  });
  return res;
};

export const login = async (param: { email: string; password: string }) => {
  const res = await request({
    url: getUrl(ApiId.LOGIN),
    method: HttpMethod.POST,
    data: param,
  });
  return res;
};

export const logout = async () => {
  const res = await request({
    url: getUrl(ApiId.LOGOUT),
    method: HttpMethod.GET,
  });
  return res;
};

// -------顧客成り代わり開始---------------
export type 顧客成り代わり開始Request = {
  user_id: string;
};
export type 顧客成り代わり開始Response = {
  data: {
    user_id: string;
    name: string;
    role: string;
  };
} & APICommonResponse;
export const 顧客成り代わり開始 = async (param: 顧客成り代わり開始Request) => {
  const res = await request<顧客成り代わり開始Response>({
    url: getUrl(ApiId.顧客成り代わり開始),
    method: HttpMethod.POST,
    data: makeParam(param),
  });
  return res;
};
// -------店舗成り代わり開始---------------
export type 店舗成り代わり開始Request = {
  user_id: string;
};
export type 店舗成り代わり開始Response = {
  data: {
    user_id: string;
    name: string;
    role: string;
  };
} & APICommonResponse;
export const 店舗成り代わり開始 = async (param: 店舗成り代わり開始Request) => {
  const res = await request<店舗成り代わり開始Response>({
    url: getUrl(ApiId.店舗成り代わり開始),
    method: HttpMethod.POST,
    data: makeParam(param),
  });
  return res;
};

// -------成り代わり終了---------------
export type 成り代わり終了Request = {};
export type 成り代わり終了Response = {} & APICommonResponse;
export const 成り代わり終了 = async (param: 成り代わり終了Request) => {
  const res = await request<成り代わり終了Response>({
    url: getUrl(ApiId.成り代わり終了),
    method: HttpMethod.GET,
    data: new URLSearchParams(param),
  });
  return res;
};
